<!-- 胆码组弹窗组件-->
<template>
    <div id="dialog">
        <div class="border">
            <div @click="closeDialog" class="closeImg"><svg t="1634463638558" class="icon" viewBox="0 0 1024 1024"
                    version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1908" width="100%" height="100%">
                    <path
                        d="M823.04 823.04A439.893333 439.893333 0 1 1 341.333333 106.666667a439.893333 439.893333 0 0 1 481.706667 716.373333zM512 0a512 512 0 1 0 512 512A512 512 0 0 0 512 0z m205.226667 306.346667a35.84 35.84 0 0 0-50.773334 0L512 460.8 357.546667 306.346667a35.84 35.84 0 0 0-51.2 50.773333L461.226667 512l-154.453334 154.88a35.84 35.84 0 1 0 51.2 50.773333L512 563.2l154.453333 154.88a35.84 35.84 0 1 0 51.2-50.773333L562.773333 512l154.453334-154.88a35.84 35.84 0 0 0 0-50.773333z"
                        fill="#5E5C5C" p-id="1909"></path>
                </svg></div>
            <div style="font-size:16px;margin-top:5px;">蓝球处理</div>
            <div class="wind">
                <!-- 号码选择区 -->
                <div class="win-ball">
                    <div class="number">
                        <div class="ball" :class="{ active: arr.indexOf(i) !== -1 }" v-for="(item, i) in 16" :key="i"
                            @click="ball(i)">
                            {{ 10 > item ? "0" + item : item }}
                        </div>
                        <div class="clear"></div>
                    </div>
                    <!-- 尾数选择区 -->
                    <div>
                        <!-- <input type='checkbox' class="" value="1" name="item" @click="ball3(i)"  color="" v-for="(item ,i) in radio" :key="i"> {{item}} -->
                        <ul>
                            <li v-for="(item ,i) in radio" :key="i" @click="radio1(i)">
                                <input class='radio' type="radio" name="food" value="1">{{item}}
                            </li>
                        </ul>
                        <div class="clear"></div>
                    </div>
                </div>
                <!-- 出号个数-->
                <!-- <div class="win-geshu">
                    <div class="win-geshu1">
                        <a>出现个数：</a>
                        <button class="button2" @click="ball2(i)" :class="{ active1: arr1.indexOf(i) !== -1 }"
                            v-for="(item ,i) in button2" :key="i">{{item}}</button>
                    </div>
                    <div class="win-geshu2">
                        <button class="button3" @click="add()" name='添加'>添加</button>
                    </div>
                </div> -->
                <!-- 条件显示区 -->
                <!-- <div class="win-tiaojian">
                    <div v-for="(item ,i) in  condition" :key="i">
                        <div class="win-t1">胆码组{{item.dmz}}</div>
                        <div class="win-t2">{{item.dm}}出{{item.gs}}个</div>
                        <div class=" wint3" :class="{ wint31: arr3.indexOf(i) !== -1 }" @click="revise(i,item)">修改</div>
                        <div class="win-t4" @click="del(i)">删除</div>
                    </div>
                </div> -->
                <div class="win-tianjia">
                    <div class="win-t5">
                        说明:<br>
                        循环使用：循环所选的蓝球去匹配红球号码。
                        逐一使用：每个蓝球和所有红球号码都组合一次。
                        均分使用：将红球注数平分成和蓝球个数相同的份数，每一份和其中一个蓝球进行组合。
                    </div>
                    <div class="win-t6">
                        <div class="win-t7" @click="confirm()">
                            确认修改
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // import api from '@/js/mpublic.js'
    //import bus from '@/js/mitt'
    export default {
        name: "ssq-wind-blue",
        data() {
            return {
                arr: [],//下标
                arr0: [],//号码

                radio: ['循环使用', '逐一使用', '平均使用'],

                bal: [
                    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16
                ],
                condition: [],

            }
        },
        mounted() {
            // this.rev()
            // console.log(this.data)
        },
        computed: {
            // sd() {
            //     return this.$store.state.sd;
            // },

        },
        methods: {

            ball(i) { //号码点击选择函数
                if (this.arr.indexOf(i) == -1) {
                    this.arr.push(i);
                    this.arr0.push(i + 1)
                } else {
                    this.arr.splice(this.arr.indexOf(i), 1);
                    this.arr0.splice(this.arr0.indexOf(i + 1), 1);
                }
            },

            ball2(i) { //出号个数函数
                if (this.arr1.indexOf(i) == -1) {
                    this.arr1.push(i);
                    //this.arr2.push(i + 1)
                } else {
                    this.arr1.splice(this.arr1.indexOf(i), 1);
                    // this.arr2.splice(this.arr2.indexOf(i + 1), 1);
                }

            },

            confirm() {//确认修改
                // this.$emit('func', this.condition)
                if (this.arr0 == '') {
                    alert('对不起！没有选择蓝球')
                } else if (document.getElementsByClassName('radio')[0].checked != true && document.getElementsByClassName('radio')[1].checked != true && document.getElementsByClassName('radio')[2].checked != true) {
                    alert('对不起！没有选择蓝球处理方式')
                } else {
                    for (var i = 0; i < document.getElementsByClassName('radio').length; i++) {
                        if (document.getElementsByClassName('radio')[i].checked == true) {
                            var obj = {
                                'id': i,
                                'data': this.arr0,
                            }
                            //obj
                           // bus.emit('bus', obj)
                            this.$emit('childByValue', obj)
                            this.$emit('closeDialog', false)//给父组件传值，并传递数据
                        }
                    }

                }
               

            },

            radio1(i) {
                document.getElementsByClassName('radio')[i].checked = true
            },

            closeDialog() {  //给父组件传参
                this.$emit('closeDialog', false)
            }



        },
        props: [],
    }
</script>

<style scoped>
    /* *{
        border:1px solid red;
    } */
    #dialog {
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.3);
        width: 100%;
        height: 100%;
    }

    .border {
        text-align: center;
        background-color: white;
        border-radius: 20px;
        width: 400px;
        height: 300px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .closeImg {
        width: 30px;
        height: 30px;
        float: right;
        margin-right: 5px;
        margin-top: 5px;
        cursor: pointer;
        /* background-image: url(../../assets/close.png); */
        /* background-color:rgb(194, 192, 192); */
        background-size: cover;
        background-repeat: no-repeat;
    }

    ul {
        margin: 0, auto;
        margin-top: 15px;
    }

    ul li {
        float: left;
        width: 30%;
        margin: 0, auto;
        margin-left: 2%;
    }

    .wind {
        float: left;
        width: 100%;

    }

    .win-ball {
        width: 100%;
    }

    .win-geshu {
        width: 100%;
        height: 10%;
    }

    .win-geshu1 {
        width: 80%;
        float: left;
        margin-top: 7px;
    }

    .button2 {
        margin-left: 5px;
    }

    .win-geshu2 {
        width: 18%;
        height: 80%;
        float: right;
        margin-top: 5px;
    }

    .button3 {
        width: 90%;
        height: 100%;
        border: 1px solid rgb(160, 158, 158);
        border-radius: 5px;
        color: aliceblue;
        background-color: rgba(252, 153, 24, 0.993);
    }

    .win-tiaojian {
        width: 99%;
        height: 135px;
        overflow: auto;
        border: 1px solid rgb(226, 224, 224);
    }

    .win-tianjia {
        width: 100%;
        margin-top: 20px;
    }

    .number {
        width: 68%;
        margin: 0 auto;
        border-radius: 10px;
        border: 1px solid rgb(214, 213, 213);
    }

    .ball {
        float: left;
        margin-left: 6px;
        margin-top: 6px;
        line-height: 25px;
        width: 25px;
        height: 25px;
        font-size: 12px;
        border-radius: 12px;

        color: rgb(124, 122, 122);
        font-weight: bold;
        /* border: 1px solid red; */
        background-color: rgb(212, 211, 211);
    }

    .active {
        color: rgb(255, 251, 251);
        font-weight: bold;
        /* border: 1px solid red; */
        background-color: rgb(52, 8, 250);

    }


    .button {
        border: 1px solid rgb(167, 166, 166);
    }

    .active1 {
        background-color: red;
        color: aliceblue;
    }

    .win-t1 {
        width: 15%;
        align-items: center;
        display: inline-block;
    }

    .win-t2 {
        width: 60%;
        word-break: break-all;
        display: inline-block;
    }

    .wint3 {
        width: 10%;
        display: inline-block;
    }

    .wint31 {
        width: 10%;
        display: inline-block;
        background-color: red;
        color: aliceblue;
        border-radius: 5px;
    }

    .win-t4 {
        width: 10%;
        display: inline-block;
    }

    .win-t5 {
        width: 90%;
        display: inline-block;
        word-break: break-all;
        text-align: left;

    }

    .win-t6 {
        width: 18%;
        height: 100%;
        margin-top: 10px;
        display: inline-block;
    }

    .win-t7 {
        width: 100%;
        height: 28px;
        background-color: red;
        color: aliceblue;
        display: inline-block;
        border-radius: 5px;
        line-height: 27px;

    }

    .clear {
        clear: both;
    }
</style>